.semi-circle {
    width: 1rem;
    height: .5rem;
    border-width: 1px;
}

.semi-circle.low {
    border-radius: 0 0 .5rem .5rem;
    border-top-color: white !important;
}

.semi-circle.high {
    border-radius: .5rem .5rem 0 0;
    border-bottom-color: white !important;
}
