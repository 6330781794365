html {
    font-size: 15px;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
    @apply px-4 lg:px-8 mx-auto;
}

.rich-text p {
    display: block !important;
    margin: 1em 0 !important;
}

.rich-text ul {
    display: block !important;
    list-style-type: disc !important;
    margin: 1em 0 1em !important;
    padding-left: 40px !important;
}

.rich-text ol {
    display: block !important;
    list-style-type: decimal !important;
    margin: 1em 0 !important;
    padding-left: 40px !important;
}

.rich-text li {
    display: list-item;
}
