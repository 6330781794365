.horizontal-slider {
    position: relative;
    width: 100%;
    max-width: 500px;
    height: 40px;
    margin: auto;
}

.example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: #ffffff;
    border: 10px solid #3774ff;
    border-radius: 100%;
    display: block;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
}

.example-thumb.active {
    background-color: grey;
}

.example-track {
    position: relative;
    background: #ddd;
}

.example-track.example-track-1 {
    background: #83a9ff;
}

.horizontal-slider .example-track {
    top: 10px;
    height: 4px;
}

.horizontal-slider .example-thumb {
    top: 2px;
    width: 10px;
    outline: none;
    height: 10px;
    line-height: 38px;
}
